import { gql, useApolloClient } from "@apollo/client";
import {
  OrgId,
  isKnownPostOrgPickerRedirect,
  showCreatorOnboarding,
} from "@hex/common";
import { useCallback } from "react";

import { LAST_ORG_STORAGE_KEY } from "../../orgs.js";
import { Routes } from "../../route/routes.js";
import { safeLocalStorage } from "../../util/browserStorage.js";
import { createRequestHeaders } from "../../util/headers.js";

import {
  GetPersonaForWorkspacePickerDocument,
  GetPersonaForWorkspacePickerQuery,
} from "./useWorkspacePickerNav.generated.js";

gql`
  query GetPersonaForWorkspacePicker {
    me {
      id
      createdDate
      persona
      orgRole
    }
  }
`;

/**
 * Returns a function that routes users after selecting an org in the picker:
 * - if redirect specified, redirect to that URL w/ a prepended org ID
 * - else if should see onboarding, redirect to onboarding
 * - else redirect to home
 *
 * The function will also set the last org value in local storage, and then requery for user info.
 */ export const usePostWorkspacePickerRedirect = (): (({
  orgId,
  redirectTo,
}: {
  orgId: OrgId;
  redirectTo: string;
}) => Promise<void>) => {
  const client = useApolloClient();
  return useCallback(
    async ({ orgId, redirectTo }: { orgId: OrgId; redirectTo: string }) => {
      safeLocalStorage.setItem(LAST_ORG_STORAGE_KEY, orgId);
      const data = await client.query<GetPersonaForWorkspacePickerQuery>({
        query: GetPersonaForWorkspacePickerDocument,
        context: {
          // also need to set the headers here because the default request
          // headers does not always get the updated local storage value.
          headers: createRequestHeaders(orgId),
        },
      });

      if (data.data.me == null) {
        window.location.href = Routes.href(orgId, true, Routes.HOME, {});
        return;
      }

      const { orgRole, persona } = data.data.me;
      const createdDate = new Date(data.data.me.createdDate);

      const shouldShowCreatorOnboarding = orgRole
        ? showCreatorOnboarding({ createdDate, orgRole, persona })
        : false;

      // we are using `window.location.href` below since we are navigating to a new org and want to reload the app.
      const isKnownRedirect = isKnownPostOrgPickerRedirect(redirectTo);
      const fullRedirectPath = `/${orgId}${redirectTo}`;
      if (shouldShowCreatorOnboarding) {
        const params = isKnownRedirect
          ? { redirectTo: fullRedirectPath }
          : undefined;
        window.location.href = Routes.href(orgId, true, Routes.ONBOARDING, {
          params,
        });
      } else if (isKnownRedirect) {
        window.location.href = fullRedirectPath;
      } else {
        window.location.href = Routes.href(orgId, true, Routes.HOME, {});
      }
    },
    [client],
  );
};
