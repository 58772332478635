import { Intent } from "@blueprintjs/core";
import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_VALUE_JSON,
  OrgId,
  withAttribution,
} from "@hex/common";
import { rgba } from "polished";
import React, { useCallback } from "react";
import styled from "styled-components";

import { NavigationBar } from "../../components/app/NavigationBar";
import { useToaster } from "../../components/common/Toasts";
import { HexButton, HexCleanLink } from "../../hex-components";
import { useAttribution } from "../../hooks/useAttribution";
import { useToggleState } from "../../hooks/useToggleState";
import { useOrgsPickerClientQuery } from "../../orgs.generated";
import { Routes, useDictQueryParams } from "../../route/routes";
import GetStartedVoronoiMini from "../../static/illustration/get-started-voronoi-mini.inline.svg";
import GrainyTexture from "../../static/image/texture-grain.png";
import { isSelfServeEnabled } from "../../util/data";
import { createRequestHeaders } from "../../util/headers";
import { LoadingView } from "../../views/LoadingView";
import {
  SetupHeader,
  SetupTitle,
} from "../workspace-setup/WorkspaceSetupSharedStyles";

import { usePostWorkspacePickerRedirect } from "./useWorkspacePickerNav.js";
import {
  OrgSubtitleDetails,
  RowBody,
  RowTitle,
  WorkspacePicker,
} from "./WorkspacePicker";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  width: 500px;
  max-height: 90%;

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.MOBILE}px`}) {
    width: 350px;
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};

  font-size: ${({ theme }) => theme.fontSize.HEADING_THREE};
`;

export const SmallDescription = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
`;

export const CreateWorkspaceRow = styled.div`
  position: relative;

  padding: 24px 16px;

  background: ${({ theme }) => theme.backgroundColor.MUTED};
  border-radius: 2px;

  box-shadow: ${({ theme }) => theme.boxShadow.CARD_SUBTLE};

  cursor: default;
`;

export const ButtonWrapper = styled.div`
  background: ${({ theme }) => theme.backgroundColor.DEFAULT};
`;

export const CreateWorkspaceContent = styled.div`
  position: relative;

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

/**
 * This needs to be ordered first with Content after so Content is rendered
 * after the illustration wrapper and sits on top without specifying a z-index.
 */
const IllustrationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;

  color: ${({ theme }) => theme.highlightColor};

  background: ${({ theme }) => rgba(theme.highlightColor, 0.05)};
  mask: linear-gradient(270deg, white, transparent);
`;

const VoronoiIllustration = styled(GetStartedVoronoiMini)`
  position: absolute;
  /*
    We need this because we are loading a large image and are positioning
    the image. An alternative would be to load a smaller image.
  */
  top: -175px;
  left: -260px;
`;

const GrainyEffect = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(${GrainyTexture});

  opacity: ${({ theme }) => theme.bgTextureOpacity};
`;

interface WorkspacePickerPageProps {
  isSignup: boolean;
  email?: string;
}

export const WorkspacePickerPage: React.ComponentType<WorkspacePickerPageProps> =
  React.memo(function WorkspacePickerPage({ email, isSignup }) {
    const queryParams = useDictQueryParams();
    const redirectTo = queryParams["redirectTo"];
    const { data } = useOrgsPickerClientQuery();
    const [
      createNewTrialClicked,
      ,
      { setFalse: setClickFalse, setTrue: setClickTrue },
    ] = useToggleState(false);
    const toaster = useToaster();
    const attribution = useAttribution();
    const postWorkspacePickerRedirect = usePostWorkspacePickerRedirect();

    const onCreateNewTrialClick = useCallback(async () => {
      setClickTrue();
      try {
        const res = await fetch(withAttribution(`/new-trial`, attribution), {
          method: `POST`,
          headers: {
            [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE_JSON,
            ...createRequestHeaders(),
          },
        });
        const { orgId } = await res.json();
        OrgId.check(orgId);
        void postWorkspacePickerRedirect({ orgId, redirectTo });
      } catch (_) {
        toaster.show({
          message:
            "Failed to create new workspace, contact support@hex.tech if the problem persists.",
          intent: Intent.DANGER,
        });
        setClickFalse();
      }
    }, [
      setClickTrue,
      attribution,
      postWorkspacePickerRedirect,
      redirectTo,
      toaster,
      setClickFalse,
    ]);

    if (!data?.orgPickerClient.loaded) {
      return <LoadingView />;
    }

    if (data?.orgPickerClient.authenticatedUsers.length === 0) {
      // if user is signed into no clients we redirect them to login
      // they should have never got here in the first place
      window.location.href = Routes.LOGIN.getUrl({
        attribution,
        params: queryParams,
      });
      return null;
    }

    const description = isSignup
      ? "Join an existing team, or start a new one."
      : "Jump back into one of your workspaces.";

    if (isSignup && !isSelfServeEnabled) {
      // if somehow folks get to this page and self serve is not enabled kick
      // them to the login workspace picker page
      window.location.href = Routes.WORKSPACE_PICKER.getUrl({
        attribution,
        subview: "login",
      });
    }

    return (
      <Container>
        <NavigationBar
          inOnboarding={true}
          isInApp={false}
          linkEnabled={false}
        />
        <SetupHeader>
          <SetupTitle>Choose your workspace</SetupTitle>
          <Description>{description}</Description>
        </SetupHeader>
        {email && (
          <SmallDescription>
            {email} is registered with these workspaces
          </SmallDescription>
        )}
        <WorkspacePicker
          authedUsers={data?.orgPickerClient.authenticatedUsers}
          unauthUsers={data?.orgPickerClient.unauthenticatedUsers}
        ></WorkspacePicker>
        {isSignup && (
          <CreateWorkspaceRow>
            <IllustrationWrapper>
              <GrainyEffect />
              <VoronoiIllustration />
            </IllustrationWrapper>
            <CreateWorkspaceContent>
              <RowBody>
                <RowTitle>Create a new workspace</RowTitle>
                <OrgSubtitleDetails>
                  {`We'll start you on a 14 day team trial.`}
                </OrgSubtitleDetails>
              </RowBody>
              <ButtonWrapper>
                <HexButton
                  loading={createNewTrialClicked}
                  onClick={onCreateNewTrialClick}
                >
                  Create new
                </HexButton>
              </ButtonWrapper>
            </CreateWorkspaceContent>
          </CreateWorkspaceRow>
        )}
        <SmallDescription>
          {`If you don't see your workspace, `}
          <HexCleanLink
            target="_blank"
            to={
              isSignup
                ? Routes.SIGNUP.getUrl({
                    attribution,
                    params: queryParams,
                  })
                : Routes.LOGIN.getUrl({
                    attribution,
                    params: {
                      newSignIn: "true",
                      ...queryParams,
                    },
                  })
            }
          >
            try a different email.
          </HexCleanLink>
        </SmallDescription>
      </Container>
    );
  });
